import { createSlice } from '@reduxjs/toolkit'
import { setTodoItemCountAction } from './CoreSlice'

export const lmsSlice = createSlice({
  name: 'lms',
  initialState: {
    loaded: false,
    falsePositive: false,
    combinedContentMap: {},
    combinedSortedContentArray: []
  },
  reducers: {
    setLoadedAction: (state, action) => {
      state.loaded = action.payload
    },
    setFalsePositiveAction: (state, action) => {
      state.falsePositive = action.payload
    },
    setCombinedContentMapAction: (state, action) => {
      state.combinedContentMap = action.payload
    },
    setSortedContentArrayAction: (state, action) => {
      state.combinedSortedContentArray = action.payload
    },
    setCourseProgressAction: (state, action) => {
      const { lessonLocation, suspendData, courseId } = action.payload
      const newMap = { ...state.combinedContentMap }
      if (lessonLocation) {
        newMap[courseId].lesson_location = lessonLocation
      }
      if (suspendData) {
        newMap[courseId].suspend_data = suspendData
      }
      state.combinedContentMap = newMap
    }
  }
})

export const {
  setLoadedAction,
  setFalsePositiveAction,
  setCombinedContentMapAction,
  setSortedContentArrayAction,
  setCourseProgressAction
} = lmsSlice.actions

export const populateContentMaps = ({ trainings, policies, learningMoments, includeLearningMoments }) => {
  return async (dispatch) => {
    if (trainings && policies && (!includeLearningMoments || learningMoments)) {
      const userContentArray = [...trainings, ...policies]
      if (includeLearningMoments) {
        const lmSet = [...learningMoments]
        userContentArray.push(...lmSet.filter((learningMomentObject) => { return learningMomentObject.clicked !== undefined }))
      }
      const userContentMap = userContentArray.reduce((map, content) => ({ ...map, [content.id]: content }), {})

      userContentArray.sort((a, b) => {
        const first = a.clicked?._seconds || a.assignmentDate
        const second = b.clicked?._seconds || b.assignmentDate
        return second - first
      })

      dispatch(setCombinedContentMapAction(userContentMap))
      dispatch(setSortedContentArrayAction(userContentArray))

      // Calculate how many incomplete assignments we have and update accordingly
      const incompleteAssignments = userContentArray.filter(content => (content.lesson_status !== 'passed' && content.acknowledged !== true))
      dispatch(setTodoItemCountAction(incompleteAssignments.length))
    }
  }
}

export default lmsSlice.reducer

export const sendLMSLinkThunk = ({ email }) => {
  return async (dispatch, getState) => {
    try {
      const response = await fetch('/api/lms-link', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      })

      if (response.status === 200) {
        return true
      } else {
        console.debug('Failed to send LMS link')
      }
    } catch (error) {
      console.debug('Failed to send LMS link')
    }

    return false
  }
}

export const getIpAddressAndLoadLearningMomentThunk = (token) => {
  return async (dispatch, getState) => {
    let userIP = null
    try {
      const res = await fetch('/api/ip', {
        method: 'GET'
      }) // If the userIp to be returned and sent back here is null, log it in the backend so we can get an idea of how much this is going wrong

      if (res.ok) {
        const { ip } = await res.json()
        userIP = ip
      }

      if (!userIP) {
        // Fallback
        const getIpAddressResponse = await fetch('https://api.ipify.org?format=json&callback=getIP', { method: 'GET' })

        if (getIpAddressResponse.ok) {
          const { ip } = await getIpAddressResponse.json()
          userIP = ip
        }
      }
    } catch (error) { /* Ignore error, continue and send null userIP which - at the time of writing - will be handled as a false positive */ }

    const res = await fetch(`/api/open-lm?token=${token}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ userIP })
    })
    const { falsePositive } = await res.json()

    // update the state
    const currentFalsePositive = getState().lms.falsePositive
    if (falsePositive !== currentFalsePositive) {
      dispatch(setFalsePositiveAction(falsePositive))
    }
    dispatch(setLoadedAction(true))
  }
}
