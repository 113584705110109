import '../../LMSMain.css'
import { CircularProgress, Grid } from '@mui/material'
import { IoCheckmarkCircleOutline, IoEllipse, IoVideocam, IoDocumentText, IoMail } from 'react-icons/io5'
import { DateTime } from 'luxon'

function UserFeed ({ content, activeFilter, setSelectedContent, loading }) {
  const sortAndRenderContent = (content) => {
    const elms = []
    if (!loading) {
      if (content.length === 0) {
        if (activeFilter === 'assigned') {
          elms.push(
            <div key={0} className='container-padding-smallish d-flex flex-column align-items-center'>
              <IoCheckmarkCircleOutline size='6em' />
              <h3>Congrats! You have no unfinished courses assigned!</h3>
            </div>
          )
        } else if (activeFilter === 'complete') {
          elms.push(
            <div key={0} className='container-padding-smallish d-flex flex-column align-items-center'>
              <h3>You have not completed any assigned content yet.</h3>
            </div>
          )
        } else {
          // all
          elms.push(
            <div key={0} className='container-padding-smallish d-flex flex-column align-items-center'>
              <h3>You have no content assigned to your account</h3>
            </div>
          )
        }
      } else {
        content.forEach((assignedContent, i) => {
          if (assignedContent.contentType === 'course') {
            if (assignedContent.type === 'single') {
              elms.push(renderVideoSingleContent(assignedContent, i))
            } else {
              elms.push(renderCourseContent(assignedContent, i))
            }
          } else if (assignedContent.contentType === 'policy') {
            elms.push(renderPolicyContent(assignedContent, i))
          } else if (assignedContent.contentType === 'lm') { // TODO: replace these with constants
            elms.push(renderLearningMomentContent(assignedContent, i))
          }
        })
      }
    } else {
      elms.push(<div key={0} className='container-padding-smallish d-flex justify-content-center'><CircularProgress /></div>)
    }
    return elms
  }

  const renderContentStatus = (status) => {
    let color
    if (status === false || status === 'incomplete') {
      color = 'red'
    } else {
      color = 'green'
    }
    return (
      <IoEllipse size='2em' color={color} />
    )
  }

  const renderCourseContent = (content, i) => {
    const formattedDate = DateTime.fromSeconds(content.dateStarted._seconds).toFormat('MM/dd/yyyy')

    return (
      <span key={i}>
        <a style={{ cursor: 'pointer' }} name='course-card' onClick={() => setSelectedContent(content)}>
          <div className='pseudo-card d-flex flex-column align-items-center justify-content-center'>
            <img className='card-img' src={content.thumbnail} icon='file-alt' />
            <div className='w-100 d-flex flex-column justify-content-center align-items-end'>
              <div className='container-padding-small d-flex flex-row justify-content-between align-items-center card-title no-margin'>
                <span />
                <h4 className='font-a no-margin'>{content.name}</h4>
                {renderContentStatus(content.lesson_status)}
              </div>
            </div>
          </div>
        </a>
        <div className='d-flex flex-row justify-content-end align-items-center card-details'>
          <span>assigned: {formattedDate}</span>
        </div>
      </span>
    )
  }

  const renderVideoSingleContent = (content, i) => {
    const formattedDate = DateTime.fromSeconds(content.dateStarted._seconds).toFormat('MM/dd/yyyy')

    return (
      <span key={i}>
        <a style={{ cursor: 'pointer' }} name='course-card' onClick={() => setSelectedContent(content)}>
          <div className='pseudo-card d-flex flex-column align-items-center justify-content-center'>
            <img className='single-vid card-img' src={content.thumbnail} icon='file-alt' />
            <div className='w-100 d-flex flex-column justify-content-center align-items-end'>
              <div className='container-padding-small d-flex flex-row justify-content-between align-items-center card-title no-margin'>
                <span />
                <div className='d-flex flex-row justify-content-center align-items-center'>
                  <h4 className='font-a no-margin'>{content.name}</h4>
                  <IoVideocam />
                </div>

                {renderContentStatus(content.lesson_status)}
              </div>
            </div>
          </div>
        </a>
        <div className='d-flex flex-row justify-content-end align-items-center card-details'>
          <span>assigned: {formattedDate}</span>
        </div>
      </span>
    )
  }

  const renderPolicyContent = (content, i) => {
    const formattedDate = DateTime.fromISO(content.dateStarted).toFormat('MM/dd/yyyy')

    return (
      <span key={i}>
        <a style={{ cursor: 'pointer' }} name='course-card' onClick={() => setSelectedContent(content)}>
          <div className='pseudo-card d-flex flex-column align-items-center justify-content-center'>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              sx={{ width: '100%', backgroundColor: 'white', borderTopRightRadius: '2em', borderTopLeftRadius: '2em' }}
            >
              <IoDocumentText size='11em' style={{ color: 'black', marginTop: 'var(--phin-s1)', marginRight: 'var(--phin-s2)', marginLeft: 'var(--phin-s2)', marginBottom: 'var(--phin-s1)' }} />
            </Grid>

            <div className='w-100 d-flex flex-column justify-content-center align-items-end'>
              <div className='container-padding-small d-flex flex-row justify-content-between align-items-center card-title no-margin'>
                <span />
                <h4 className='font-a no-margin'>{content.name}</h4>
                {renderContentStatus(content.acknowledged)}
              </div>
            </div>
          </div>
        </a>
        <div className='d-flex flex-row justify-content-end align-items-center card-details'>
          <span>assigned: {formattedDate}</span>
        </div>
      </span>
    )
  }

  const renderLearningMomentContent = (content, i) => {
    const formattedDate = DateTime.fromSeconds(content.clicked._seconds).toFormat('MM/dd/yyyy')
    return (
      <span key={i}>
        <a onClick={() => setSelectedContent(content)} style={{ cursor: 'pointer' }} name='course-card'>
          <div className='pseudo-card d-flex flex-column align-items-center justify-content-center'>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              sx={{ width: '100%', backgroundColor: 'white', borderTopRightRadius: '2em', borderTopLeftRadius: '2em' }}
            >
              <IoMail size='11em' style={{ color: 'black', marginTop: 'var(--phin-s1)', marginRight: 'var(--phin-s2)', marginLeft: 'var(--phin-s2)', marginBottom: 'var(--phin-s1)' }} />
            </Grid>

            <div className='w-100 d-flex flex-column justify-content-center align-items-end'>
              <div className='container-padding-small d-flex flex-row justify-content-between align-items-center card-title no-margin'>
                <span />
                <h4 className='font-a no-margin'>Phishing Simulation: {content.templateDoc.subject}</h4>
                {renderContentStatus(content.learningMomentFinished !== undefined)}
              </div>
            </div>
          </div>
        </a>
        <div className='d-flex flex-row justify-content-end align-items-center card-details'>
          <span>assigned: {formattedDate}</span>
        </div>
      </span>
    )
  }

  return (
    <div className='lms-plr-1'>
      {sortAndRenderContent(content)}
    </div>
  )
}

export default UserFeed
